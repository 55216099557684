<template>
  <el-container class="elcontainer">
    <!-- 头部 -->
    <el-header height="70px" class="header">
      <div class="header_left">教学系统</div>
      <img
        @click="off"
        style="width: 30px; height: 25px"
        src="../assets/img/caidan.png"
        alt=""
      />
      <div class="header_right">
        <div>
          <span class="span1">{{ nickname }}</span>
          <img class="user_img" src="../assets/img/admin.png" alt="" />
          <span class="span2">账号: {{ username }}</span>
          <el-button
            class="Logout"
            size="medium"
            type="info"
            plain
            @click="Logout"
            >注销</el-button
          >
        </div>
      </div>
    </el-header>
    <el-container class="elcontainerbox">
      <!-- 左侧 -->
      <el-aside :width="isCollapse ? '70px' : '220px'">
        <el-menu
          :collapse-transition="false"
          :collapse="isCollapse"
          :default-active="activePath"
          background-color="#323543"
          text-color="#fff"
          active-text-color="#1CA6C8"
          unique-opened
          router
          :class="isCollapse ? 'el_menu_vertical1' : 'el_menu_vertical'"
        >
          <el-menu-item index="/welcome" @click="saveNavState('/welcome')">
            <template slot="title">
              <i class="el-icon-s-home"></i>
              <span>首页</span>
            </template>
          </el-menu-item>

          <el-submenu :index="item.id + ''" v-for="item in indexList" :key="item.id">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item :index="it.remark" @click="saveNavState(it.remark)" v-for="it in item.children" :key="it.id">
              <template slot="title">
                <span style="margin-left: 10px">{{it.title}}</span>
              </template>
            </el-menu-item>
          </el-submenu>

          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>常规管理</span>
            </template>
            <el-menu-item index="/personal" @click="saveNavState('/personal')">
              <template slot="title">
                <span style="margin-left: 10px">个人资料</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/system" @click="saveNavState('/system')">
              <template slot="title">
                <span style="margin-left: 10px">系统资料</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/annex" @click="saveNavState('/annex')">
              <template slot="title">
                <span style="margin-left: 10px">附件管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span>人员管理</span>
            </template>
            <el-menu-item
              index="/adminList"
              @click="saveNavState('/adminList')"
            >
              <template slot="title">
                <span style="margin-left: 10px">人员列表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/log" @click="saveNavState('/log')">
              <template slot="title">
                <span style="margin-left: 10px">日志列表</span>
              </template>
            </el-menu-item>

            <el-menu-item index="/role" @click="saveNavState('/role')">
              <template slot="title">
                <span style="margin-left: 10px">角色列表</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/jurisdictionList"
              @click="saveNavState('/jurisdictionList')"
            >
              <template slot="title">
                <span style="margin-left: 10px">权限列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-flag"></i>
              <span>名师管理</span>
            </template>
            <el-menu-item index="/teacher" @click="saveNavState('/teacher')">
              <template slot="title">
                <span style="margin-left: 10px">名师列表</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/reservationTime"
              @click="saveNavState('/reservationTime')"
            >
              <template slot="title">
                <span style="margin-left: 10px">预约时间</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>学校管理</span>
            </template>
            <el-menu-item index="/school" @click="saveNavState('/school')">
              <template slot="title">
                <span style="margin-left: 10px">学校列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-s-finance"></i>
              <span>资源管理</span>
            </template>
            <el-menu-item index="/resourceCate" @click="saveNavState('/resourceCate')">
              <template slot="title">
                <span style="margin-left: 10px">分类列表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/resource" @click="saveNavState('/resource')">
              <template slot="title">
                <span style="margin-left: 10px">资源列表</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/restriction"
              @click="saveNavState('/restriction')"
            >
              <template slot="title">
                <span style="margin-left: 10px">限制列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>预约管理</span>
            </template>
            <el-menu-item
              index="/reservation"
              @click="saveNavState('/reservation')"
            >
              <template slot="title">
                <span style="margin-left: 10px">预约列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>数据统计</span>
            </template>
            <el-menu-item
              index="/statistics_school"
              @click="saveNavState('/statistics_school')"
            >
              <template slot="title">
                <span style="margin-left: 10px">学校统计</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/statistics_teacher"
              @click="saveNavState('/statistics_teacher')"
            >
              <template slot="title">
                <span style="margin-left: 10px">老师统计</span>
              </template>
            </el-menu-item>
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <!-- 右侧 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isCollapse: false,
      activePath: '',

      // 用户信息
      username: '',
      nickname: '',
      indexList: [],
    }
  },
  created() {
    this.info()
    this.index()
    this.activePath = window.localStorage.getItem('jxzong_activePath')
    console.log(333, this.activePath)
  },
  methods: {
    reload() {
      this.activePath = window.localStorage.getItem('jxzong_activePath')
    },
    // 菜单列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/index/menu')
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
    },

    //  获取用户信息
    async info() {
      const { data: res } = await this.$http.get(
        'edulogin.php/general/profile/info'
      )
      console.log(res)

      this.username = res.data.username
      this.nickname = res.data.nickname
    },

    // 注销
    async Logout() {
      const { data: res } = await this.$http.get('edulogin.php/index/logout')
      if (res.code != 1) return
      window.localStorage.removeItem('jxzong_activePath')
      this.$router.push('/login')
    },

    // 切换
    off() {
      this.isCollapse = !this.isCollapse
    },

    // 保存链接的激活状态
    saveNavState(activePath) {
      console.log(11)
      window.localStorage.setItem('jxzong_activePath', activePath)
      this.activePath = activePath
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: #323543;
  color: #fff;
  .header_left {
    width: 220px;
    height: 100%;
    font-size: 24px !important;
    text-align: center;
    line-height: 60px;
  }
  .header_right {
    flex: auto;
    height: 60px;
    // border-bottom: 1px solid #dfe3e8;
    // box-shadow: 0 0 10px #dfe3e8;
    line-height: 60px;
    div {
      float: right;
      display: flex;
      align-items: center;
    }
    .span1 {
      font-size: 18px;
    }
    .user_img {
      display: block;
      width: 46px;
      height: 46px;
      border-radius: 23px;
      margin-left: 20px;
    }
    .span2 {
      font-size: 16px;
      margin: 0 30px 0 6px;
    }
    .Logout {
      margin-right: 30px;
    }
  }
}
.elcontainer {
  height: 100vh;
}
.elcontainerbox {
  height: calc(100% - 70px);
}
aside,
menu,
main {
  height: 100%;
}
aside {
  background-color: #323543;
  // width: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  .aside_zuo {
    width: 66px;

    height: 44px;
    background-color: #1b1f24;
    float: right;
    text-align: center;
    line-height: 44px;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    color: #b5b9bd;
    margin-top: 10px;
  }
}

.el-main {
  background-color: #edf2f8;
  padding: 10px;
}
.el-submenu [class^='el-icon-'] {
  font-size: 16px !important;
}
.el_menu_vertical {
  width: 220px;
}
.el_menu_vertical1 {
  width: 70px;
}
.el-menu-item {
  background-color: rgb(36, 38, 46) !important;
}
.el-menu-item.is-active {
  color: #fff !important;
  background-color: rgb(28, 166, 200) !important;
}
.el-icon-s-home {
  font-size: 16px!important;
}
</style>


